define("ember-changeset-validations/validators/number", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "ember-validators/number"], function (_exports, _validationErrors, _withDefaults, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNumber;

  function validateNumber() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = (0, _withDefaults.default)(options, {
      allowString: true,
      allowNone: false
    });

    if (options.allowBlank) {
      options.allowNone = true;
    }

    return function (key, value) {
      var result = (0, _number.default)(value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});