define("ember-pikaday/mixins/pikaday", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _options: Ember.computed('options', 'i18n', {
      get: function get() {
        var options = this._defaultOptions();

        if (Ember.isPresent(this.get('i18n'))) {
          if (Ember.isPresent(this.get('i18n').t)) {
            options.i18n = {
              previousMonth: this.get('i18n').t('previousMonth').toString(),
              nextMonth: this.get('i18n').t('nextMonth').toString(),
              months: this.get('i18n').t('months').toString().split(','),
              weekdays: this.get('i18n').t('weekdays').toString().split(','),
              weekdaysShort: this.get('i18n').t('weekdaysShort').toString().split(',')
            };
          } else {
            options.i18n = this.get('i18n');
          }
        }

        if (Ember.isPresent(this.get('position'))) {
          options.position = this.get('position');
        }

        if (Ember.isPresent(this.get('reposition'))) {
          options.reposition = this.get('reposition');
        }

        Ember.assign(options, this.get('options') || {});
        return options;
      }
    }),
    _defaultOptions: function _defaultOptions() {
      var firstDay = this.get('firstDay');
      return {
        field: this.get('field'),
        container: this.get('pikadayContainer'),
        bound: this.get('pikadayContainer') ? false : true,
        onOpen: Ember.run.bind(this, this.onPikadayOpen),
        onClose: Ember.run.bind(this, this.onPikadayClose),
        onSelect: Ember.run.bind(this, this.onPikadaySelect),
        onDraw: Ember.run.bind(this, this.onPikadayRedraw),
        firstDay: typeof firstDay !== 'undefined' ? parseInt(firstDay, 10) : 1,
        format: this.get('format') || 'DD.MM.YYYY',
        yearRange: this.determineYearRange(),
        minDate: this.get('minDate') || null,
        maxDate: this.get('maxDate') || null,
        defaultDate: this.get('defaultDate') || null,
        setDefaultDate: !!this.get('defaultDate'),
        theme: this.get('theme') || null
      };
    },

    /**
     * When updating attrs, we need to reset some things in case they've changed.
     * @public
     * @memberOf {Mixins.Pikaday}
     * @return {undefined}
     */
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this.set('cancelToken', Ember.run.later(function () {
        // Do not set or update anything when the component is destroying.
        if (_this.get('isDestroying') || _this.get('isDestroyed')) {
          return;
        }

        _this.setMinDate();

        _this.setMaxDate();

        _this.setPikadayDate();

        if (_this.get('options')) {
          _this._updateOptions();
        }
      }));
    },
    didRender: function didRender() {
      this._super();

      this.autoHideOnDisabled();
    },
    setupPikaday: function setupPikaday() {
      var pikaday = new Pikaday(this.get('_options'));

      if (this.get('defaultDate')) {
        this.set('value', this.get('defaultDate'));
      }

      this.set('pikaday', pikaday);
      this.setPikadayDate();
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      this.get('pikaday').destroy();
      Ember.run.cancel(this.get('cancelToken'));
    },
    setPikadayDate: function setPikadayDate() {
      var format = 'YYYY-MM-DD';
      var value = this.get('value');

      if (!value) {
        this.get('pikaday').setDate(value, true);
      } else {
        var date = this.get('useUTC') ? (0, _moment.default)(_moment.default.utc(value).format(format), format).toDate() : value;
        this.get('pikaday').setDate(date, true);
      }
    },
    setMinDate: function setMinDate() {
      var _this2 = this;

      var _Ember$getProperties = Ember.getProperties(this, ['pikaday', 'minDate', 'value']),
          pikaday = _Ember$getProperties.pikaday,
          minDate = _Ember$getProperties.minDate,
          value = _Ember$getProperties.value;

      if (minDate) {
        var _minDate = new Date(minDate.getTime());

        pikaday.setMinDate(_minDate); // If the current date is lower than minDate we set date to minDate

        Ember.run.next(function () {
          if (value && (0, _moment.default)(value, _this2.get('format')).isBefore(minDate, 'day')) {
            pikaday.setDate(minDate);
          }
        });
      } else {
        pikaday.setMinDate(null);
      }
    },
    setMaxDate: function setMaxDate() {
      var _Ember$getProperties2 = Ember.getProperties(this, ['pikaday', 'maxDate', 'value']),
          pikaday = _Ember$getProperties2.pikaday,
          maxDate = _Ember$getProperties2.maxDate,
          value = _Ember$getProperties2.value;

      if (maxDate) {
        var _maxDate = new Date(maxDate.getTime());

        pikaday.setMaxDate(_maxDate); // If the current date is greater than maxDate we set date to maxDate

        Ember.run.next(function () {
          if (value > maxDate) {
            pikaday.setDate(maxDate);
          }
        });
      } else {
        pikaday.setMaxDate(null);
      }
    },
    onOpen: function onOpen() {},
    onClose: function onClose() {},
    onSelection: function onSelection() {},
    onDraw: function onDraw() {},
    onPikadaySelect: function onPikadaySelect() {
      this.userSelectedDate();
    },
    onPikadayRedraw: function onPikadayRedraw() {
      this.get('onDraw')();
    },
    userSelectedDate: function userSelectedDate() {
      var selectedDate = this.get('pikaday').getDate();

      if (this.get('useUTC')) {
        selectedDate = _moment.default.utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
      }

      this.get('onSelection')(selectedDate);
    },
    determineYearRange: function determineYearRange() {
      var yearRange = this.get('yearRange');

      if (yearRange) {
        if (yearRange.indexOf(',') > -1) {
          var yearArray = yearRange.split(',');

          if (yearArray[1] === 'currentYear') {
            yearArray[1] = new Date().getFullYear();
          }

          return yearArray;
        } else {
          return yearRange;
        }
      } else {
        return 10;
      }
    },
    autoHideOnDisabled: function autoHideOnDisabled() {
      if (this.get('disabled') && this.get('pikaday')) {
        this.get('pikaday').hide();
      }
    },
    _updateOptions: function _updateOptions() {
      this.get('pikaday').config(this.get('_options'));
    }
  });

  _exports.default = _default;
});