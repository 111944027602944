define("ember-paper/components/paper-radio-group", ["exports", "ember-paper/templates/components/paper-radio-group", "ember-paper/mixins/focusable-mixin", "ember-composability-tools", "ember-invoke-action"], function (_exports, _paperRadioGroup, _focusableMixin, _emberComposabilityTools, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperRadioGroup
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ParentMixin
   */
  var _default = Ember.Component.extend(_focusableMixin.default, _emberComposabilityTools.ParentMixin, {
    layout: _paperRadioGroup.default,
    tagName: 'md-radio-group',
    tabindex: 0,

    /* FocusableMixin Overrides */
    focusOnlyOnKey: true,
    radioComponent: 'paper-radio',
    labelComponent: 'paper-radio-group-label',
    role: 'radiogroup',
    constants: Ember.inject.service(),
    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.get('onChange') !== undefined) && Ember.assert('{{paper-radio-group}} requires an `onChange` action or null for no action', this.get('onChange') !== undefined));
    },
    attributeBindings: ['role', 'ariaLabelledby:aria-labelledby'],
    enabledChildRadios: Ember.computed.filterBy('childComponents', 'disabled', false),
    childValues: Ember.computed.mapBy('enabledChildRadios', 'value'),
    hasLabel: Ember.computed.notEmpty('labelNode'),
    keyDown: function keyDown(ev) {
      switch (ev.which) {
        case this.get('constants.KEYCODE.LEFT_ARROW'):
        case this.get('constants.KEYCODE.UP_ARROW'):
          ev.preventDefault();
          this.select(-1);
          break;

        case this.get('constants.KEYCODE.RIGHT_ARROW'):
        case this.get('constants.KEYCODE.DOWN_ARROW'):
          ev.preventDefault();
          this.select(1);
          break;
      }
    },
    select: function select(increment) {
      var groupValue = this.get('groupValue');
      var index = 0;

      if (Ember.isPresent(groupValue)) {
        index = this.get('childValues').indexOf(groupValue);
        index += increment;
        var length = this.get('childValues.length');
        index = (index % length + length) % length;
      }

      var childRadio = this.get('enabledChildRadios').objectAt(index);
      childRadio.set('focused', true);
      (0, _emberInvokeAction.invokeAction)(this, 'onChange', childRadio.get('value'));
    },
    actions: {
      onChange: function onChange(value) {
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', value);
      }
    }
  });

  _exports.default = _default;
});