define('ember-css-transitions/mixins/transition-mixin', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cAF = exports.rAF = undefined;
  exports.nextTick = nextTick;
  exports.computeTimeout = computeTimeout;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var testing = Ember.testing;


  /**
   * @private
   * T (period) = 1 / f (frequency)
   * TICK = 1 / 60hz = 0,01667s = 17ms
   */
  var TICK = 17;

  /**
   * @public
   * This function performs some logic after a browser repaint.
   * While on testing or if raf not available, use a run-loop friendly equivalent.
   * This also makes the tests work as expected.
   */
  var rAF = exports.rAF = testing || !window.requestAnimationFrame ? function (fn) {
    return Ember.run.later(fn, TICK);
  } : window.requestAnimationFrame;

  /**
   * @public
   * This function is the counterpart of rAF. It will cancel a previously
   * scheduled task with rAF. If on testing or when rAF isn't available
   * we default to `run.cancel`.
   */
  var cAF = exports.cAF = testing || !window.cancelAnimationFrame ? function (requestID) {
    return Ember.run.cancel(requestID);
  } : window.cancelAnimationFrame;

  /**
   * @public
   * Performs some logic after DOM changes have been flushed
   * and after a browser repaint.
   */
  function nextTick() {
    return new Ember.RSVP.Promise(function (resolve) {
      Ember.run.schedule('afterRender', function () {
        rAF(function () {
          resolve();
        });
      });
    });
  }

  /**
   * @private
   * Computes the time a css animation will take.
   * Uses `getComputedStyle` to get durations and delays.
   */
  function computeTimeout(element) {
    var _window$getComputedSt = window.getComputedStyle(element),
        transitionDuration = _window$getComputedSt.transitionDuration,
        transitionDelay = _window$getComputedSt.transitionDelay,
        animationDuration = _window$getComputedSt.animationDuration,
        animationDelay = _window$getComputedSt.animationDelay,
        animationIterationCount = _window$getComputedSt.animationIterationCount;

    // `getComputedStyle` returns durations and delays in the Xs format.
    // Conveniently if `parseFloat` encounters a character other than a sign (+ or -),
    // numeral (0-9), a decimal point, or an exponent, it returns the value up to that point
    // and ignores that character and all succeeding characters.

    var maxDelay = Math.max(parseFloat(animationDelay), parseFloat(transitionDelay));
    var maxDuration = Math.max(parseFloat(animationDuration) * parseFloat(animationIterationCount), parseFloat(transitionDuration));

    return (maxDelay + maxDuration) * 1000;
  }

  /**
   * @public
   * @class TransitionMixin
   * @extends Ember.Mixin
   */
  exports.default = Ember.Mixin.create({

    classNameBindings: ['joinedTransitionClasses'],

    joinedTransitionClasses: Ember.computed('transitionClasses.[]', function () {
      return this.get('transitionClasses').join(' ');
    }),

    addClass: function addClass(className, element) {
      if (!this.get('isDestroying')) {
        this.get('transitionClasses').addObject(className);
      } else {
        element.classList.add(className);
      }
    },
    removeClass: function removeClass(className, element) {
      if (!this.get('isDestroying')) {
        this.get('transitionClasses').removeObject(className);
      } else {
        element.classList.remove(className);
      }
    },


    'transition-class': Ember.computed.alias('transitionClass'),
    transitionName: Ember.computed.alias('transitionClass'),

    /**
     * @private
     * Transitions the element.
     * @param animationType The animation type, e.g. "enter" or "leave".
     * @param transitionClass The name of the class with the transition defined
     * @return Promise
     */
    transition: function transition(animationType, transitionClass, finishCallback) {
      var _this = this;

      // we may need to animate the clone if the element was destroyed
      var element = this.clone || this.element;

      var className = transitionClass + '-' + animationType;
      var activeClassName = className + '-active';

      // add first class right away
      this.addClass(className, element);

      nextTick().then(function () {
        // This is for to force a repaint,
        // which is necessary in order to transition styles when adding a class name.
        element.scrollTop;
        // add active class after repaint
        _this.addClass(activeClassName, element);

        // if we're animating a class removal
        // we need to remove the class
        if (animationType === 'remove') {
          _this.removeClass(transitionClass, element);
        }

        // wait for ember to apply classes
        Ember.run.schedule('afterRender', function () {
          // set timeout for animation end
          var timeout = Ember.run.later(function () {
            _this.removeClass(className, element);
            _this.removeClass(activeClassName, element);
            if (finishCallback) {
              finishCallback();
            }
          }, computeTimeout(element) || 0);
          _this.transitionTimeouts.push(timeout);
        });
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var transitionClass = this.get('transitionName');
      if (transitionClass) {
        var animationType = 'enter';
        var className = transitionClass + '-' + animationType;
        this.transitionClasses = Ember.A([className]);
      } else {
        this.transitionClasses = Ember.A();
      }

      this.transitionTimeouts = [];
      this._setupTriggerObservers();
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var transitionClass = this.get('transitionName');
      if (transitionClass) {
        Ember.run.schedule('afterRender', function () {
          _this2.transition('enter', transitionClass, _this2.didTransitionIn);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this._teardownTriggerObservers();
      this.transitionTimeouts.forEach(function (t) {
        return clearTimeout(t);
      });

      var transitionClass = this.get('transitionName');
      if (transitionClass) {
        // We can't stop ember from removing the element
        // so we clone the element to animate it out
        var clone = this.clone = this.element.cloneNode(true);

        clone.setAttribute('id', this.elementId + '_clone');

        this.addDestroyedElementClone(this.element, clone);

        nextTick().then(function () {
          _this3.transition('leave', transitionClass, function () {
            _this3.didTransitionOut();
            delete _this3.clone;
          });
        });
      }
    },


    /**
     * @public
     * Default placement of the cloned element when being destroyed.
     */
    addDestroyedElementClone: function addDestroyedElementClone(original, clone) {
      original.parentNode.insertBefore(clone, original.nextElementSibling);
    },


    /**
     * @public
     * Called after transition in was done. Will always be called after didInsertElement.
     */
    didTransitionIn: function didTransitionIn() {},


    /**
     * @public
     * Called when the transition out is called.
     * Call removeChild() from parentNode for IE11 support
     * @param clone The cloned element. Should be called to remove the element after transition is done.
     */
    didTransitionOut: function didTransitionOut() {
      if (this.clone.parentNode !== null) {
        this.clone.parentNode.removeChild(this.clone);
      }
    },


    /**
     * A list of properties that can control the transitions.  Functions just like
     * Ember.Component.classNameBindings, and can be formatted in the same way.
     *
     * @property transitionClassNameBindings
     * @type Array
     * @default []
     * @public
     */
    transitionClassNameBindings: [],

    _setupTriggerObservers: function _setupTriggerObservers() {
      var _this4 = this;

      this._observers = {};
      this.get('transitionClassNameBindings').forEach(function (classExp) {
        var _classExp$split = classExp.split(':'),
            _classExp$split2 = _slicedToArray(_classExp$split, 2),
            propName = _classExp$split2[0],
            className = _classExp$split2[1];

        if (!className) {
          className = (0, _string.dasherize)(propName);
        }

        // create observer function
        _this4._observers[propName] = function () {
          var value = this.get(propName);
          if (value) {
            this.addClass(className, this.element);
            this.transition('add', className);
          } else {
            this.transition('remove', className);
          }
        };

        // if value starts as true, add it immediatly
        var value = _this4.get(propName);
        if (value) {
          _this4.get('transitionClasses').addObject(className);
        }

        // add observer
        _this4.addObserver(propName, _this4, _this4._observers[propName]);
      });
    },
    _teardownTriggerObservers: function _teardownTriggerObservers() {
      var _this5 = this;

      if (this._observers) {
        this.get('transitionClassNameBindings').forEach(function (classExp) {
          var _classExp$split3 = classExp.split(':'),
              _classExp$split4 = _slicedToArray(_classExp$split3, 1),
              propName = _classExp$split4[0];

          _this5.removeObserver(propName, _this5, _this5._observers[propName]);
          delete _this5._observers[propName];
        });
      }
    }
  });
});