define("ember-paper/components/paper-autocomplete/ebd-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VvsXs/r3",
    "block": "{\"symbols\":[\"@dropdown\",\"@required\",\"@label\",\"@eventType\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"shouldShowLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,\"for\",[31,[\"ember-basic-dropdown-trigger-\",[32,1,[\"uniqueId\"]]]]],[15,0,[30,[36,0],[[32,2],\"md-required\"],null]],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"basic-dropdown-trigger\",[[17,8],[16,\"md-floating-label\",[30,[36,0],[[32,3],[32,3]],null]],[16,0,[30,[36,0],[[32,3],\"md-floating-label\"],null]],[16,1,[31,[\"ember-basic-dropdown-trigger-\",[32,1,[\"uniqueId\"]]]]],[16,\"disabled\",[32,9]],[24,\"tabindex\",\"-1\"]],[[\"@htmlTag\",\"@eventType\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-autocomplete\",[32,4],[32,1],[32,5],[32,6],[32,7]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,10,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-paper/components/paper-autocomplete/ebd-trigger/template.hbs"
  });

  _exports.default = _default;
});