define("ember-paper/components/paper-toolbar", ["exports", "ember-paper/templates/components/paper-toolbar", "ember-paper/mixins/color-mixin"], function (_exports, _paperToolbar, _colorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperToolbar
   * @extends Ember.Component
   * @uses ColorMixin
   */
  var _default = Ember.Component.extend(_colorMixin.default, {
    layout: _paperToolbar.default,
    tagName: 'md-toolbar',
    classNames: ['md-default-theme'],
    tall: false,
    classNameBindings: ['tall:md-tall']
  });

  _exports.default = _default;
});