define("ember-paper/validators/maxlength", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.maxlength = maxlength;

  function maxlength(value, maxlength) {
    return Ember.isEmpty(maxlength) || Ember.isNone(value) || "".concat(value).length <= parseInt(maxlength, 10);
  }

  var _default = {
    param: 'maxlength',
    message: 'Must not exceed %@ characters.',
    validate: maxlength
  };
  _exports.default = _default;
});