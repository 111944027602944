define("ember-initials/components/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SvBX6TUo",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"img\"],[17,1],[16,0,[32,0,[\"class\"]]],[16,\"width\",[32,0,[\"width\"]]],[16,\"height\",[32,0,[\"height\"]]],[16,\"src\",[32,0,[\"src\"]]],[16,\"alt\",[32,0,[\"alt\"]]],[16,\"title\",[32,0,[\"title\"]]],[16,\"onError\",[32,0,[\"onError\"]]],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-initials/components/image/template.hbs"
  });

  _exports.default = _default;
});