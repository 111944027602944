define("ember-moment/services/moment", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-classic-classes
  var _default = Ember.Service.extend(Ember.Evented, {
    _timeZone: null,
    locale: null,
    localeOptions: null,
    defaultFormat: null,
    __config__: Ember.computed(function () {
      var config = Ember.getOwner(this).factoryFor('config:environment').class || {};
      return config.moment || {};
    }).readOnly(),
    timeZone: Ember.computed('_timeZone', {
      get: function get() {
        return this._timeZone;
      },
      set: function set(propertyKey, timeZone) {
        if (!_momentTimezone.default.tz) {
          /* eslint-disable no-console */
          console.warn('[ember-moment] attempted to set timezone, but moment-timezone is not setup.');
          return;
        }

        Ember.set(this, '_timeZone', timeZone);
        return timeZone;
      }
    }),
    setLocale: function setLocale(locale) {
      this.changeLocale(locale);
    },
    updateLocale: function updateLocale(locale) {
      var localeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.changeLocale(locale, localeOptions);
    },
    changeLocale: function changeLocale(locale) {
      var localeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      Ember.setProperties(this, {
        locale: locale,
        localeOptions: localeOptions
      });

      _momentTimezone.default.updateLocale(locale, localeOptions);

      this.trigger('localeChanged', locale);
    },
    setTimeZone: function setTimeZone(timeZone) {
      this.changeTimeZone(timeZone);
    },
    changeTimeZone: function changeTimeZone(timeZone) {
      Ember.set(this, 'timeZone', timeZone);
      this.trigger('timeZoneChanged', timeZone);
    },
    isMoment: function isMoment(obj) {
      return _momentTimezone.default.isMoment(obj);
    },
    moment: function moment() {
      var momentObj = _momentTimezone.default.apply(void 0, arguments);

      var locale = this.locale,
          timeZone = this.timeZone;

      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }

      if (timeZone && momentObj.tz) {
        momentObj = momentObj.tz(timeZone);
      }

      return momentObj;
    },
    utc: function utc() {
      var momentObj = _momentTimezone.default.utc.apply(_momentTimezone.default, arguments);

      var locale = this.locale;

      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }

      return momentObj;
    }
  });

  _exports.default = _default;
});