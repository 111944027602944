define("ember-power-select-sortable/components/power-select-sortable/trigger", ["exports", "ember-power-select/components/power-select-multiple/trigger", "ember-power-select-sortable/templates/components/power-select-sortable/trigger"], function (_exports, _trigger, _trigger2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trigger.default.extend({
    layout: _trigger2.default,
    actions: {
      reorderItems: function reorderItems(items) {
        this.select.actions.select(items);
      }
    }
  });

  _exports.default = _default;
});