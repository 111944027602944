define("ember-paper/components/paper-ink-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'md-ink-bar',
    attributeBindings: ['style'],
    classNameBindings: ['movingRight:md-right:md-left'],
    style: Ember.computed('left', 'right', function () {
      return Ember.String.htmlSafe("left: ".concat(this.get('left'), "px; right: ").concat(this.get('right'), "px;"));
    })
  });

  _exports.default = _default;
});