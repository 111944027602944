define("ember-paper/components/paper-speed-dial-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'md-fab-trigger',
    click: function click() {
      this.get('speedDial').toggle();
    },
    focusOut: function focusOut() {
      this.get('speedDial').close();
    }
  });

  _exports.default = _default;
});