define("ember-validators/utils/validation-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validationError;

  function validationError(type, value, context, message) {
    return {
      type: type,
      value: value,
      context: context,
      message: message
    };
  }
});