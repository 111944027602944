define("ember-paper/components/paper-sidenav-inner", ["exports", "ember-css-transitions/mixins/transition-mixin", "ember-invoke-action"], function (_exports, _transitionMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperSidenavInner
   * @extends Ember.Component
   * @uses TransitionMixin
   */
  var _default = Ember.Component.extend(_transitionMixin.default, {
    tagName: 'md-sidenav',
    attributeBindings: ['tabindex'],
    classNameBindings: ['positionClass'],
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    transitionClassNameBindings: ['isLockedOpen:md-locked-open', 'closed:md-closed'],
    constants: Ember.inject.service(),
    paperSidenav: Ember.inject.service(),
    name: 'default',
    position: 'left',
    lockedOpen: 'gt-sm',
    closed: true,
    closeOnClick: true,
    tabindex: -1,
    positionClass: Ember.computed('position', function () {
      return "md-sidenav-".concat(this.get('position'));
    }),
    init: function init() {
      // need to updateLockedOpen() first because otherwise the transition classes
      // would be applied due to transition mixin's `init`
      if (typeof FastBoot === 'undefined') {
        this.updateLockedOpen();
      }

      this._super.apply(this, arguments);

      this.get('paperSidenav').register(this.get('name'), this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateOnResize = Ember.run.bind(this, this.updateLockedOpen);
      window.addEventListener('resize', this._updateOnResize);
      this.updateLockedOpen();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (typeof FastBoot === 'undefined') {
        this.updateLockedOpen();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this._updateOnResize);
      this.get('paperSidenav').unregister(this.get('name'), this);
      this._updateOnResize = null;
    },
    updateLockedOpen: function updateLockedOpen() {
      var lockedOpen = this.get('lockedOpen');
      var isLockedOpen; // if `true` or `false` is specified, always/never "lock open"
      // otherwise proceed with normal matchMedia test

      if (typeof lockedOpen === 'boolean') {
        isLockedOpen = lockedOpen;
      } else {
        var mediaQuery = this.get('constants').MEDIA[lockedOpen] || lockedOpen;
        isLockedOpen = window.matchMedia(mediaQuery).matches;
      }

      var coercedIsLockedOpen = !!this.get('isLockedOpen');

      if (coercedIsLockedOpen !== isLockedOpen) {
        this.set('isLockedOpen', isLockedOpen); // if sidenav is open and we enter lockedOpen,
        // make the sidenav enter the "closed" state

        if (!this.get('closed') && isLockedOpen) {
          (0, _emberInvokeAction.invokeAction)(this, 'onToggle', false);
        }
      }
    },
    click: function click() {
      if (this.get('closeOnClick') && !this.get('isLockedOpen')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onToggle', false);
      }
    },
    open: function open() {
      if (this.get('closed') && this.get('isLockedOpen')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onToggle', true);
      }
    },
    close: function close() {
      if (!this.get('closed') && !this.get('isLockedOpen')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onToggle', false);
      }
    },
    toggle: function toggle() {
      if (!this.get('isLockedOpen')) {
        (0, _emberInvokeAction.invokeAction)(this, 'onToggle', this.get('closed'));
      }
    }
  });

  _exports.default = _default;
});