define("ember-power-select-sortable/components/power-select-sortable", ["exports", "ember-power-select-sortable/templates/components/power-select-sortable"], function (_exports, _powerSelectSortable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _powerSelectSortable.default,
    tagName: '',
    triggerComponent: "power-select-sortable/trigger"
  });

  _exports.default = _default;
});