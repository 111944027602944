define("ember-simple-auth-auth0/utils/get-session-expiration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSessionExpiration;

  /**
   * Get the token expiration time from the specified session data object.
   * If an ID token is defined, use the `exp` field since it's nice and
   * well-defined. Otherwise, calculate an expiration time from the
   * expiresIn field and the time the session object was created.
   *
   * @return {Expiration time of token (Unix timestamp)}
   */
  function getSessionExpiration() {
    var _sessionData$idTokenP;

    var sessionData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var idTokenExpiration = (_sessionData$idTokenP = sessionData.idTokenPayload) === null || _sessionData$idTokenP === void 0 ? void 0 : _sessionData$idTokenP.exp;

    if (idTokenExpiration) {
      return idTokenExpiration;
    } else {
      var _sessionData$issuedAt, _sessionData$expiresI;

      var issuedAt = (_sessionData$issuedAt = sessionData.issuedAt) !== null && _sessionData$issuedAt !== void 0 ? _sessionData$issuedAt : 0;
      var expiresIn = (_sessionData$expiresI = sessionData.expiresIn) !== null && _sessionData$expiresI !== void 0 ? _sessionData$expiresI : 0;
      return issuedAt + expiresIn;
    }
  }
});