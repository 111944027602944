define("layout-bin-packer/entry", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Entry;
  function Entry(height, width, x, y) {
    this.height = height;
    this.width = width;
    this.position = { x: x, y: y };
  }
});