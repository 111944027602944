define("ember-paper/utils/calculate-tooltip-position", ["exports", "ember-paper/utils/clamp"], function (_exports, _clamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calculateTooltipPosition;

  function calculateTooltipPosition(tooltip, target, position) {
    var panelBounds = tooltip.getBoundingClientRect();
    var panelWidth = panelBounds.width;
    var panelHeight = panelBounds.height;
    var targetBounds = target.getBoundingClientRect();
    var targetLeft = targetBounds.left;
    var targetRight = targetBounds.right;
    var targetWidth = targetBounds.width;
    var positionStyle = {};

    switch (position) {
      case 'top':
      case 'bottom':
        positionStyle.left = targetLeft + 0.5 * targetWidth - 0.5 * panelWidth;
        break;

      case 'right':
        positionStyle.left = targetRight;
        break;

      case 'left':
        positionStyle.left = targetLeft - panelWidth;
        break;
    }

    var targetTop = targetBounds.top;
    var targetBottom = targetBounds.bottom;
    var targetHeight = targetBounds.height;

    switch (position) {
      case 'right':
      case 'left':
        positionStyle.top = targetTop + 0.5 * targetHeight - 0.5 * panelHeight;
        break;

      case 'top':
        positionStyle.top = targetTop - panelHeight;
        break;

      case 'bottom':
        positionStyle.top = targetBottom;
        break;
    } // clamp position to the visible area of the viewport


    var tooltipBounds = tooltip.getBoundingClientRect(); // account for negative margins

    var _window$getComputedSt = window.getComputedStyle(tooltip),
        tooltipMarginTop = _window$getComputedSt.marginTop,
        tooltipMarginLeft = _window$getComputedSt.marginLeft;

    tooltipMarginTop = parseInt(tooltipMarginTop);
    tooltipMarginLeft = parseInt(tooltipMarginLeft);
    positionStyle.top = (0, _clamp.default)(positionStyle.top, 0 - tooltipMarginTop, window.innerHeight - tooltipBounds.height - tooltipMarginTop);
    positionStyle.left = (0, _clamp.default)(positionStyle.left, 0 - tooltipMarginLeft, window.innerWidth - tooltipBounds.width - tooltipMarginLeft);
    return positionStyle;
  }
});