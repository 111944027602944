define("ember-paper/components/paper-tooltip-inner", ["exports", "ember-paper/templates/components/paper-tooltip-inner", "ember-css-transitions/mixins/transition-mixin", "ember-paper/utils/calculate-tooltip-position"], function (_exports, _paperTooltipInner, _transitionMixin, _calculateTooltipPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transitionMixin.default, {
    layout: _paperTooltipInner.default,
    tagName: 'md-tooltip',
    attributeBindings: ['style'],
    classNames: ['md-tooltip', 'md-panel'],
    classNameBindings: ['positionClass'],
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    transitionClassNameBindings: ['show:md-show', 'hide:md-hide'],
    show: Ember.computed.bool('style'),
    positionClass: Ember.computed('position', function () {
      return "md-origin-".concat(this.get('position'));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', function () {
        if (!_this.isDestroyed) {
          var anchorElement = _this.get('anchorElement');

          var pos = (0, _calculateTooltipPosition.default)(_this.element, anchorElement, _this.get('position'));

          _this.set('style', Ember.String.htmlSafe("top: ".concat(pos.top, "px; left: ").concat(pos.left, "px")));

          _this.set('hide', true);

          (0, _transitionMixin.nextTick)().then(_transitionMixin.nextTick).then(_transitionMixin.nextTick).then(_transitionMixin.nextTick).then(function () {
            if (!_this.isDestroyed) {
              _this.set('hide', false);
            }
          });
        }
      });
    }
  });

  _exports.default = _default;
});