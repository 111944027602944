define("ember-table/components/ember-th/resize-handle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSftLKRM",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isResizable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"et-header-resize-area\"],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-table/components/ember-th/resize-handle/template.hbs"
  });

  _exports.default = _default;
});