define("ember-paper/mixins/proxiable-mixin", ["exports", "ember-composability-tools"], function (_exports, _emberComposabilityTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ProxiableMixin
   * @uses ChildMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create(_emberComposabilityTools.ChildMixin, {
    classNameBindings: ['secondary:md-secondary'],
    shouldRegister: false,
    registerWithParent: function registerWithParent() {
      Ember.run.next(this, this._super);
    },
    mouseDown: function mouseDown() {
      this._super.apply(this, arguments);

      var parentComponent = this.get('parentComponent');

      if (parentComponent) {
        parentComponent.set('mouseActive', true);
        Ember.run.later(function () {
          if (parentComponent.isDestroyed) {
            return;
          }

          parentComponent.set('mouseActive', false);
        }, 100);
      }
    },
    focusIn: function focusIn() {
      this._super.apply(this, arguments);

      var parentComponent = this.get('parentComponent');

      if (parentComponent && !parentComponent.get('mouseActive')) {
        parentComponent.set('focused', true);
      }
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var parentComponent = this.get('parentComponent');

      if (parentComponent) {
        parentComponent.set('focused', false);
      }
    }
  });

  _exports.default = _default;
});