define("ember-concurrency/initializers/ember-concurrency", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This initializer exists only to make sure that the following
  // imports happen before the app boots.
  var _default = {
    name: 'ember-concurrency',
    initialize: function initialize() {}
  };
  _exports.default = _default;
});