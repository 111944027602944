define("ember-paper/templates/components/paper-progress-linear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WC+z5R63",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"md-container \",[34,0]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-dashed\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-bar md-bar1\"],[15,5,[34,1]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-bar md-bar2\"],[15,5,[34,2]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"queryModeClass\",\"bar1Style\",\"bar2Style\"]}",
    "moduleName": "ember-paper/templates/components/paper-progress-linear.hbs"
  });

  _exports.default = _default;
});