define("ember-paper/templates/components/paper-radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Od0/auuO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,9],null,[[\"label\",\"radio\"],[[30,[36,5],[[35,8]],[[\"setAriaLabelledby\"],[[30,[36,0],[[32,0],[30,[36,7],[[35,6]],null]],null]]]],[30,[36,5],[[35,4]],[[\"toggle\",\"disabled\",\"groupValue\",\"onChange\",\"parentComponent\",\"shouldRegister\"],[[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onChange\"],null],[32,0],true]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"groupValue\",\"disabled\",\"toggle\",\"radioComponent\",\"component\",\"ariaLabelledby\",\"mut\",\"labelComponent\",\"hash\"]}",
    "moduleName": "ember-paper/templates/components/paper-radio-group.hbs"
  });

  _exports.default = _default;
});