define('ember-cli-mentionable/components/mentionable-picker', ['exports', 'ember-cli-mentionable/templates/components/mentionable-picker'], function (exports, _mentionablePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;


  var WRAPPER_CLASS = 'mentionable-picker-wrapper';

  exports.default = Ember.Component.extend({
    layout: _mentionablePicker.default,
    classNames: [WRAPPER_CLASS],
    matchingValues: null,
    selectedValue: null,
    showPicker: null,
    actions: {
      didSelectValue: function didSelectValue(selectedValue) {
        this.selectValue(selectedValue);
      },
      didKeyDown: function didKeyDown(selectedValue, event) {
        this.handleKeyDown(selectedValue, event);
      }
    },

    handleKeyDown: function handleKeyDown(selectedValue, event) {
      if (isEmpty(this.get('matchingValues'))) {
        return;
      }
      event.preventDefault();
      switch (event.keyCode) {
        case 38:
          // arrow up
          this.selectPrevious();
          break;
        case 40:
          // arrow down
          this.selectNext();
          break;
        case 13:
          // enter
          this.selectValue(selectedValue);
          this.sendAction('didPressEnter'); // eslint-disable-line ember/closure-actions
          break;
        case 27:
          // escape
          this.sendAction('focusInput'); // eslint-disable-line ember/closure-actions
          this.sendAction('didPressEscape'); // eslint-disable-line ember/closure-actions
          break;
        default:
          this.$('.active').removeClass('active');
          this.sendAction('updateKeypress', event); // eslint-disable-line ember/closure-actions
          break;
      }
    },
    selectPrevious: function selectPrevious() {
      var $active = this.$('.active');
      var $next = $active.prev('li');
      if ($next.length === 0) {
        $next = this.$('li').last();
      }
      $active.removeClass('active');
      $next.addClass('active').focus();
    },
    selectNext: function selectNext() {
      var $active = this.$('.active');
      var $next = $active.next('li');
      if ($next.length === 0) {
        $next = this.$('li').first();
      }
      $active.removeClass('active');
      $next.addClass('active').focus();
    },
    selectValue: function selectValue(selectedValue) {
      this.sendAction('didSelectValue', selectedValue); // eslint-disable-line ember/closure-actions
    },
    focusIn: function focusIn(event) {
      if (Ember.$(event.target).hasClass(this.get('pickerClass'))) {
        this.selectNext();
      }
    }
  });
});