define("ember-collection/utils/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = identity;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function identity(item) {
    var key;

    var type = _typeof(item);

    if (type === 'string' || type === 'number') {
      key = item;
    } else {
      key = Ember.guidFor(item);
    }

    return key;
  }
});