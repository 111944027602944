define("ember-svg-jar/inlined/teams", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8.5\" cy=\"14.5\" r=\"1.5\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M17 15h4a1 1 0 011 1v4a1 1 0 01-1 1h-2a3 3 0 01-3-3v-2a1 1 0 011-1z\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M22 18h-6M16 16l.057-4.867\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M10.158 3.195A9.2 9.2 0 003 12.297v.606A7.022 7.022 0 0010 20v0a3 3 0 003-3v-3.493a1.99 1.99 0 011.45-1.916l5.374-1.518a1.006 1.006 0 00.666-1.33 9.067 9.067 0 00-10.332-5.548z\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M21 15h-8\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});