define("lodash/extend", ["exports", "lodash/assignIn"], function (_exports, _assignIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _assignIn.default;
    }
  });
});