define('ember-element-helper/helpers/-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function UNINITIALIZED() {}

  exports.default = Ember.Helper.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.tagName = UNINITIALIZED;
      this.componentName = UNINITIALIZED;
    },
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          tagName = _ref2[0];

      if (tagName === this.tagName) {
        return this.componentName;
      } else if (typeof tagName !== 'string') {
        var message = 'the argument passed to the `element` helper must be a string';

        try {
          message += ' (you passed `' + tagName + '`)';
        } catch (e) {
          // ignore
        }

        throw new Error(message);
      } else {
        this.tagName = tagName;

        // return a different component name to force a teardown
        if (this.componentName === '-dynamic-element') {
          return this.componentName = '-dynamic-element-alt';
        } else {
          return this.componentName = '-dynamic-element';
        }
      }
    }
  });
});