define("ember-cli-nouislider/components/range-slider", ["exports", "noUiSlider"], function (_exports, _noUiSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var warn = Ember.Logger.warn;

  var _default = Ember.Component.extend({
    attributeBindings: ['disabledOrUndefined:disabled'],
    slider: null,
    start: undefined,
    step: undefined,
    margin: undefined,
    padding: undefined,
    limit: undefined,
    pips: undefined,
    animate: true,
    snap: false,
    connect: false,
    disabled: false,
    orientation: 'horizontal',
    direction: 'ltr',
    behaviour: 'tap',
    tooltips: false,
    multitouch: false,
    keyboardSupport: true,
    min: 0,
    max: 100,
    range: Ember.computed('min', 'max', function () {
      return {
        min: this.get('min'),
        max: this.get('max')
      };
    }),
    formatTo: function formatTo(value) {
      return value;
    },
    formatFrom: function formatFrom(value) {
      return +value;
    },
    format: Ember.computed('formatTo', 'formatFrom', function () {
      return {
        to: this.get('formatTo'),
        from: this.get('formatFrom')
      };
    }),
    didInsertElement: function didInsertElement() {
      this.setup();
    },
    setup: function setup() {
      var _this = this;

      var element = this.get('element');
      var slider = element.noUiSlider;
      var properties = this.getProperties('start', 'step', 'margin', 'padding', 'limit', 'range', 'connect', 'orientation', 'direction', 'behaviour', 'animate', 'snap', 'pips', 'format', 'tooltips', 'multitouch', 'cssPrefix', 'cssClasses', 'keyboardSupport');
      var sliderEvents = Ember.A(['change', 'set', 'slide', 'update']); // We first check if the element has a slider already created

      if (slider && slider.destroy) {
        slider.destroy();
      }

      try {
        slider = _noUiSlider.default.create(element, properties, true);
      } catch (err) {
        warn("[ember-cli-nouislider]: ".concat(err));
      }

      this.slider = slider;
      sliderEvents.forEach(function (event) {
        var eventActionName = "on-".concat(event);

        if (!Ember.isEmpty(_this.get(eventActionName))) {
          slider.on(event, function () {
            Ember.run(_this, function () {
              var val = this.get('slider').get();
              var action = this.get(eventActionName);

              if (typeof action === 'string') {
                // Note that `sendAction` is deprecated and this will trigger a deprecation message.
                this.sendAction(eventActionName, val);
              } else if (typeof action === 'function') {
                action(val);
              }
            });
          });
        }
      });
      slider.on('start', function () {
        Ember.run(_this, function () {
          this.onStart();

          if (!Ember.isEmpty(this.get("on-start"))) {
            var val = this.get("slider").get();
            this.sendAction("on-start", val);
          }
        });
      });
      slider.on('end', function () {
        Ember.run(_this, function () {
          this.onEnd();

          if (!Ember.isEmpty(this.get("on-end"))) {
            var val = this.get("slider").get();
            this.sendAction("on-end", val);
          }
        });
      });
    },
    onStart: function onStart() {
      this.sliding = true;
    },
    onEnd: function onEnd() {
      delete this.sliding;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.update();
    },
    update: function update() {
      var slider = this.slider;
      var properties = this.getProperties('margin', 'limit', 'step', 'range', 'animate', 'snap', 'start', 'padding', 'keyboardSupport');

      if (slider) {
        slider.updateOptions(properties);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.teardown();
    },
    teardown: function teardown() {
      var slider = this.slider; // Remove all event listeners

      slider.off();
      slider.destroy();
    },
    setValue: Ember.observer('start', function () {
      var slider = this.slider;

      if (slider && !this.sliding) {
        var value = this.get('start');
        slider.set(value);
      }
    }),
    // disabled can't be just `false` - this leads to an attribute of disabled="false"
    disabledOrUndefined: Ember.computed('disabled', function () {
      if (this.get('disabled')) {
        return true;
      }

      return undefined;
    })
  });

  _exports.default = _default;
});