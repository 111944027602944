define("ember-paper/components/paper-card-header", ["exports", "ember-paper/templates/components/paper-card-header"], function (_exports, _paperCardHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperCardHeader
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperCardHeader.default,
    tagName: 'md-card-header'
  });

  _exports.default = _default;
});