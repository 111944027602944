define('ember-autosave/computed-autosave', ['exports', 'ember-autosave/autosave-proxy'], function (exports, _autosaveProxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = computedAutosave;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function computedAutosave() {
    var propertyName = void 0,
        options = void 0;

    if (typeof arguments[0] === 'string') {
      var _arguments = Array.prototype.slice.call(arguments);

      propertyName = _arguments[0];
      var _arguments$ = _arguments[1];
      options = _arguments$ === undefined ? {} : _arguments$;
    } else if (_typeof(arguments[0]) === 'object') {
      options = arguments[0];
    } else {
      throw new Error('Invalid arguments passed to computedAutosave: ', arguments);
    }

    var computedArgs = {
      get: function get() {
        options.context = this;

        var model = void 0;
        if (propertyName) {
          model = Ember.get(this, propertyName);
        }

        return _autosaveProxy.default.create(model, options);
      }
    };

    if (propertyName) {
      return Ember.computed(propertyName, computedArgs);
    } else {
      return Ember.computed(computedArgs);
    }
  }
});