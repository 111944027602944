define("ember-paper/templates/components/paper-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PSi/Eerr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"title\",\"content\",\"actions\",\"header\",\"image\",\"media\"],[[30,[36,0],[\"paper-card-title\"],null],[30,[36,0],[\"paper-card-content\"],null],[30,[36,0],[\"paper-card-actions\"],null],[30,[36,0],[\"paper-card-header\"],null],[30,[36,0],[\"paper-card-image\"],null],[30,[36,0],[\"paper-card-media\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-paper/templates/components/paper-card.hbs"
  });

  _exports.default = _default;
});