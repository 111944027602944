define("ember-paper/components/paper-progress-linear", ["exports", "ember-paper/templates/components/paper-progress-linear", "ember-paper/mixins/color-mixin"], function (_exports, _paperProgressLinear, _colorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function makeTransform(value) {
    var scale = value / 100;
    var translateX = (value - 100) / 2;
    return "translateX(".concat(translateX.toString(), "%) scale(").concat(scale.toString(), ", 1)");
  }

  var MODE_DETERMINATE = 'determinate';
  var MODE_INDETERMINATE = 'indeterminate';
  var MODE_BUFFER = 'buffer';
  var MODE_QUERY = 'query';
  /**
   * @class PaperProgressLinear
   * @extends Ember.Component
   * @uses ColorMixin
   */

  var _default = Ember.Component.extend(_colorMixin.default, {
    layout: _paperProgressLinear.default,
    tagName: 'md-progress-linear',
    attributeBindings: ['mode:md-mode', 'bufferValue:md-buffer-value'],
    classNames: ['md-default-theme'],
    constants: Ember.inject.service(),
    mode: Ember.computed('value', {
      get: function get() {
        if (this._mode !== undefined) {
          return this._mode;
        }

        var value = this.get('value');
        var bufferValue = this.get('bufferValue');

        if (Ember.isPresent(value)) {
          if (Ember.isPresent(bufferValue)) {
            return MODE_BUFFER;
          } else {
            return MODE_DETERMINATE;
          }
        } else {
          return MODE_INDETERMINATE;
        }
      },
      set: function set(key, value) {
        return this._mode = value;
      }
    }),
    queryModeClass: Ember.computed('mode', function () {
      var mode = this.get('mode');

      if ([MODE_QUERY, MODE_BUFFER, MODE_DETERMINATE, MODE_INDETERMINATE].includes(mode)) {
        return "md-mode-".concat(mode);
      } else {
        return '';
      }
    }),
    bar1Style: Ember.computed('clampedBufferValue', function () {
      return Ember.String.htmlSafe("".concat(this.get('constants.CSS.TRANSFORM'), ": ").concat(makeTransform(this.get('clampedBufferValue'))));
    }),
    bar2Style: Ember.computed('clampedValue', 'mode', function () {
      if (this.get('mode') === MODE_QUERY) {
        return Ember.String.htmlSafe('');
      }

      return Ember.String.htmlSafe("".concat(this.get('constants.CSS.TRANSFORM'), ": ").concat(makeTransform(this.get('clampedValue'))));
    }),
    clampedValue: Ember.computed('value', function () {
      var value = this.get('value');
      return Math.max(0, Math.min(value || 0, 100));
    }),
    clampedBufferValue: Ember.computed('bufferValue', function () {
      var value = this.get('bufferValue');
      return Math.max(0, Math.min(value || 0, 100));
    })
  });

  _exports.default = _default;
});