define("ember-concurrency-decorators/last-value", ["exports", "@ember-decorators/utils/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This decorator allows you to alias a property to the result of a task. You can also provide a default value to use before the task has completed.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { lastValue } from 'ember-concurrency-decorators';
   *
   * export default class ExampleComponent extends Component {
   *   @task
   *   someTask = function*() {
   *     // ...
   *   };
   *
   *   @lastValue('someTask')
   *   someTaskValue;
   *
   *   @lastValue('someTask')
   *   someTaskValueWithDefault = 'A default value';
   * }
   * ```
   *
   * @function
   * @param {string} taskName the name of the task to read a value from
   */
  // eslint-disable-next-line func-names
  var _default = (0, _decorator.decoratorWithRequiredParams)(function lastValue(target, key, desc, _ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        taskName = _ref2[0];

    (false && !(typeof taskName === 'string') && Ember.assert("ember-concurrency-decorators: @lastValue expects a task name as the first parameter.", typeof taskName === 'string'));
    var initializer = desc.initializer;
    delete desc.initializer;
    var cp = Ember.computed("".concat(taskName, ".lastSuccessful"), function () {
      var lastInstance = Ember.get(this, "".concat(taskName, ".lastSuccessful"));

      if (lastInstance) {
        return Ember.get(lastInstance, 'value');
      }

      if (initializer) {
        return initializer.call(this);
      }

      return undefined;
    }); // @ts-ignore

    return cp(target, key, desc);
  });

  _exports.default = _default;
});