define("lodash/value", ["exports", "lodash/wrapperValue"], function (_exports, _wrapperValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wrapperValue.default;
    }
  });
});