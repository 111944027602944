define('ember-cli-mentionable/components/mentionable-input', ['exports', 'ember-cli-mentionable/mixins/mentionable-component', 'ember-cli-mentionable/templates/components/mentionable-input'], function (exports, _mentionableComponent, _mentionableInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mentionableComponent.default, {
    layout: _mentionableInput.default,
    type: 'text',
    classNames: ['mentionable-component', 'mentionable-input']
  });
});