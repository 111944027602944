define("ember-paper/components/paper-select/ebd-trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nx0pWDLb",
    "block": "{\"symbols\":[\"@dropdown\",\"@required\",\"@label\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"shouldShowLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,\"for\",[31,[\"ember-basic-dropdown-trigger-\",[32,1,[\"uniqueId\"]]]]],[15,0,[30,[36,0],[[32,2],\"md-required\"],null]],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"basic-dropdown-trigger\",[[17,7],[16,1,[31,[\"ember-basic-dropdown-trigger-\",[32,1,[\"uniqueId\"]]]]],[16,\"disabled\",[32,8]],[16,\"required\",[32,2]]],[[\"@htmlTag\",\"@eventType\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-select\",\"click\",[32,1],[32,4],[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,9,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-paper/components/paper-select/ebd-trigger/template.hbs"
  });

  _exports.default = _default;
});