define("ember-paper/components/paper-card-header-text", ["exports", "ember-paper/templates/components/paper-card-header-text"], function (_exports, _paperCardHeaderText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperCardheaderText
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperCardHeaderText.default,
    tagName: 'md-card-header-text'
  });

  _exports.default = _default;
});