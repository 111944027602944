define("ember-svg-jar/inlined/finance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 9H1.5M5 15H3.5M5 12H2.5M5.636 5.636a9 9 0 110 12.728M12 7.5v1M12 16.5v-1\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14.193 9.921A2.195 2.195 0 0012 8.5a2.069 2.069 0 00-2.25 1.818c0 .716.506 1.333 1.208 1.474l2.084.416c.702.14 1.208.758 1.208 1.474A2.069 2.069 0 0112 15.5a2.195 2.195 0 01-2.193-1.421\" stroke=\"#323232\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});