define("ember-simple-auth-auth0/utils/now", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = now;

  function now() {
    return Math.ceil(Date.now() / 1000);
  }
});