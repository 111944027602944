define("ember-paper/components/paper-select/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PaXxOsUh",
    "block": "{\"symbols\":[\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\",\"@searchEnabled\"],\"statements\":[[6,[37,2],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"md-select-header\"],[14,0,\"ember-power-select-search md-searchbox\"],[12],[2,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[24,\"spellcheck\",\"false\"],[24,\"role\",\"combobox\"],[24,0,\"ember-power-select-search-input md-searchinput\"],[16,2,[32,1,[\"searchText\"]]],[16,\"aria-controls\",[32,2]],[16,\"placeholder\",[32,3]],[24,4,\"search\"],[4,[38,0],[\"input\",[32,4]],null],[4,[38,0],[\"focus\",[32,5]],null],[4,[38,0],[\"blur\",[32,6]],null],[4,[38,0],[\"keydown\",[32,0,[\"handleKeydown\"]]],null],[4,[38,1],[[32,0,[\"focusInput\"]]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"if\"]}",
    "moduleName": "ember-paper/components/paper-select/search/template.hbs"
  });

  _exports.default = _default;
});