define("ember-paper/components/paper-icon", ["exports", "ember-paper/templates/components/paper-icon", "ember-paper/mixins/color-mixin"], function (_exports, _paperIcon, _colorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperIcon
   * @extends Ember.Component
   * @uses ColorMixin
   */
  var PaperIconComponent = Ember.Component.extend(_colorMixin.default, {
    layout: _paperIcon.default,
    tagName: 'md-icon',
    classNames: ['paper-icon', 'md-font', 'material-icons', 'md-default-theme'],
    classNameBindings: ['spinClass'],
    attributeBindings: ['aria-hidden', 'aria-label', 'title', 'sizeStyle:style', 'iconClass:md-font-icon'],
    icon: '',
    spin: false,
    reverseSpin: false,
    iconClass: Ember.computed('icon', 'positionalIcon', function () {
      var icon = this.getWithDefault('positionalIcon', this.get('icon'));
      return icon;
    }),
    'aria-hidden': false,
    'aria-label': Ember.computed.reads('iconClass'),
    spinClass: Ember.computed('spin', 'reverseSpin', function () {
      if (this.get('spin')) {
        return 'md-spin';
      } else if (this.get('reverseSpin')) {
        return 'md-spin-reverse';
      } else {
        return null;
      }
    }),
    sizeStyle: Ember.computed('size', function () {
      var size = this.get('size');

      if (size) {
        return Ember.String.htmlSafe("height: ".concat(size, "px; min-height: ").concat(size, "px; min-width: ").concat(size, "px; font-size: ").concat(size, "px; line-height: ").concat(size, "px;"));
      } else {
        return null;
      }
    })
  });
  PaperIconComponent.reopenClass({
    positionalParams: ['positionalIcon']
  });
  var _default = PaperIconComponent;
  _exports.default = _default;
});