define("ember-paper/templates/components/paper-toast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6CVp7O8D",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,11],[[35,10]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[6,[37,9],null,[[\"swipe\",\"swipeToClose\",\"onClose\",\"top\",\"left\",\"capsule\",\"class\"],[[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,1],null,[[\"text\"],[[30,[36,0],[\"paper-toast-text\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"class\",\"capsule\",\"left\",\"top\",\"onClose\",\"swipeToClose\",\"swipeAction\",\"paper-toast-inner\",\"destinationEl\",\"in-element\"]}",
    "moduleName": "ember-paper/templates/components/paper-toast.hbs"
  });

  _exports.default = _default;
});