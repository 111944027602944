define("ember-basic-dropdown/utils/scroll-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LINES_PER_PAGE = _exports.DOM_DELTA_PIXEL = _exports.DOM_DELTA_PAGE = _exports.DOM_DELTA_LINE = void 0;
  _exports.distributeScroll = distributeScroll;
  _exports.getAvailableScroll = getAvailableScroll;
  _exports.getScrollDeltas = getScrollDeltas;
  _exports.getScrollLineHeight = getScrollLineHeight;

  /**
   * Mode that expresses the deltas in pixels.
   */
  var DOM_DELTA_PIXEL = 0;
  /**
   * Mode that expresses the deltas in lines.
   *
   * This only happens in Firefox for Windows.
   *
   * Reference: https://stackoverflow.com/a/37474225
   */

  _exports.DOM_DELTA_PIXEL = DOM_DELTA_PIXEL;
  var DOM_DELTA_LINE = 1;
  /**
   * Mode that expresses the deltas in pages.
   *
   * This only happens in Firefox for Windows with
   * a custom OS setting activated.
   *
   * Reference: https://stackoverflow.com/a/37474225
   */

  _exports.DOM_DELTA_LINE = DOM_DELTA_LINE;
  var DOM_DELTA_PAGE = 2;
  /**
   * Number of lines per page considered for
   * DOM_DELTA_PAGE.
   *
   */

  _exports.DOM_DELTA_PAGE = DOM_DELTA_PAGE;
  var LINES_PER_PAGE = 3;
  _exports.LINES_PER_PAGE = LINES_PER_PAGE;
  var scrollLineHeight = undefined;

  function getScrollDeltas(_ref) {
    var _ref$deltaX = _ref.deltaX,
        deltaX = _ref$deltaX === void 0 ? 0 : _ref$deltaX,
        _ref$deltaY = _ref.deltaY,
        deltaY = _ref$deltaY === void 0 ? 0 : _ref$deltaY,
        _ref$deltaMode = _ref.deltaMode,
        deltaMode = _ref$deltaMode === void 0 ? DOM_DELTA_PIXEL : _ref$deltaMode;

    if (deltaMode !== DOM_DELTA_PIXEL) {
      if (deltaMode === DOM_DELTA_PAGE) {
        deltaX *= LINES_PER_PAGE;
        deltaY *= LINES_PER_PAGE;
      }

      var _scrollLineHeight = getScrollLineHeight();

      if (_scrollLineHeight !== undefined) {
        deltaX *= _scrollLineHeight;
        deltaY *= _scrollLineHeight;
      }
    }

    return {
      deltaX: deltaX,
      deltaY: deltaY
    };
  }

  function getScrollLineHeight() {
    if (scrollLineHeight === undefined) {
      var iframe = document.createElement('iframe');
      iframe.src = '#';
      iframe.style.position = 'absolute';
      iframe.style.visibility = 'hidden';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.style.border = 'none';
      document.body.appendChild(iframe);
      var iframeDocument = iframe.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write('<!doctype html><html><head></head><body><span>X</span></body></html>');
      iframeDocument.close();
      var body = iframeDocument.body;
      scrollLineHeight = body.firstElementChild.offsetHeight;
      document.body.removeChild(iframe);
    }

    return scrollLineHeight;
  }

  function getAvailableScroll(element, container) {
    var availableScroll = {
      deltaXNegative: 0,
      deltaXPositive: 0,
      deltaYNegative: 0,
      deltaYPositive: 0
    };
    var scrollLeftMax, scrollTopMax;

    while (container.contains(element) || container === element) {
      scrollLeftMax = element.scrollWidth - element.clientWidth;
      scrollTopMax = element.scrollHeight - element.clientHeight;
      availableScroll.deltaXNegative += -element.scrollLeft;
      availableScroll.deltaXPositive += scrollLeftMax - element.scrollLeft;
      availableScroll.deltaYNegative += -element.scrollTop;
      availableScroll.deltaYPositive += scrollTopMax - element.scrollTop;
      var parent = element.parentNode;
      if (parent === null) break;
      element = parent;
    }

    return availableScroll;
  }
  /**
   * Calculates the scroll distribution for `element` inside` container.
   */


  function calculateScrollDistribution(deltaX, deltaY, element, container) {
    var accumulator = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    var scrollInformation = {
      element: element,
      scrollLeft: 0,
      scrollTop: 0
    };
    var scrollLeftMax = element.scrollWidth - element.clientWidth;
    var scrollTopMax = element.scrollHeight - element.clientHeight;
    var availableScroll = {
      deltaXNegative: -element.scrollLeft,
      deltaXPositive: scrollLeftMax - element.scrollLeft,
      deltaYNegative: -element.scrollTop,
      deltaYPositive: scrollTopMax - element.scrollTop
    };
    var elementStyle = window.getComputedStyle(element);

    if (elementStyle.overflowX !== 'hidden') {
      // The `deltaX` can be larger than the available scroll for the element, thus overshooting.
      // The result of that is that it scrolls the element as far as possible. We don't need to
      // calculate exactly because we reduce the amount of desired scroll for the
      // parent elements by the correct amount below.
      scrollInformation.scrollLeft = element.scrollLeft + deltaX;

      if (deltaX > availableScroll.deltaXPositive) {
        deltaX = deltaX - availableScroll.deltaXPositive;
      } else if (deltaX < availableScroll.deltaXNegative) {
        deltaX = deltaX - availableScroll.deltaXNegative;
      } else {
        deltaX = 0;
      }
    }

    if (elementStyle.overflowY !== 'hidden') {
      scrollInformation.scrollTop = element.scrollTop + deltaY;

      if (deltaY > availableScroll.deltaYPositive) {
        deltaY = deltaY - availableScroll.deltaYPositive;
      } else if (deltaY < availableScroll.deltaYNegative) {
        deltaY = deltaY - availableScroll.deltaYNegative;
      } else {
        deltaY = 0;
      }
    }

    if (element !== container && (deltaX || deltaY)) {
      return calculateScrollDistribution(deltaX, deltaY, element.parentNode, container, accumulator.concat([scrollInformation]));
    }

    return accumulator.concat([scrollInformation]);
  } // Recursively walks up scroll containers until the delta is distributed or we
  // run out of elements in the allowed-to-scroll container.


  function distributeScroll(deltaX, deltaY, element, container) {
    var scrollInfos = calculateScrollDistribution(deltaX, deltaY, element, container);
    var info;

    for (var i = 0; i < scrollInfos.length; i++) {
      info = scrollInfos[i];
      info.element.scrollLeft = info.scrollLeft;
      info.element.scrollTop = info.scrollTop;
    }
  }
});