define("ember-paper/services/paper-toaster", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_PROPS = {
    duration: 3000,
    position: 'bottom left'
  };

  var _default = Ember.Service.extend({
    queue: Ember.A(),
    activeToast: Ember.computed.reads('queue.firstObject'),
    show: function show(text, options) {
      var t = Ember.Object.create(Ember.assign({
        text: text,
        show: true
      }, this.buildOptions(options)));
      this.get('queue').pushObject(t);
      return t;
    },
    showComponent: function showComponent(componentName, options) {
      var t = Ember.Object.create(Ember.assign({
        componentName: componentName,
        show: true
      }, this.buildOptions(options)));
      this.get('queue').pushObject(t);
      return t;
    },
    cancelToast: function cancelToast(toast) {
      var _this = this;

      toast.set('show', false);

      if (this.get('activeToast') === toast) {
        Ember.run.later(function () {
          Ember.tryInvoke(toast, 'onClose');

          _this.get('queue').removeObject(toast);
        }, 400);
      } else {
        Ember.tryInvoke(toast, 'onClose');
        this.get('queue').removeObject(toast);
      }
    },
    buildOptions: function buildOptions(options) {
      var toasterOptions = {};

      if (_emberGetConfig.default['ember-paper'] && _emberGetConfig.default['ember-paper']['paper-toaster']) {
        toasterOptions = _emberGetConfig.default['ember-paper']['paper-toaster'];
      }

      return Ember.assign({}, DEFAULT_PROPS, toasterOptions, options);
    }
  });

  _exports.default = _default;
});