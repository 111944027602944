define("ember-rollbar-client/instance-initializers/rollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(appInstance) {
    var fastbootService = appInstance.lookup('service:fastboot');
    var rollbarService = appInstance.lookup('service:rollbar');

    var oldOnError = Ember.onerror || function () {};

    Ember.onerror = function () {
      oldOnError.apply(void 0, arguments);
      var enabled = rollbarService.get('enabled');

      if (enabled) {
        rollbarService.error.apply(rollbarService, arguments);
      }

      if (!enabled || Ember.testing) {
        if (!fastbootService || !fastbootService.get('isFastBoot')) {
          throw arguments.length <= 0 ? undefined : arguments[0];
        }
      }
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});