define("ember-sortable/utils/transitionend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Thanks to http://davidwalsh.name/css-animation-callback
  function whichTransitionEvent() {
    var t;
    var el = document.createElement('fake-element');
    var transitions = {
      'transition': 'transitionend',
      'OTransition': 'oTransitionEnd',
      'MozTransition': 'transitionend',
      'WebkitTransition': 'webkitTransitionEnd'
    };

    for (t in transitions) {
      if (el.style[t] !== undefined) {
        return transitions[t];
      }
    }
  }

  var transitionend = whichTransitionEvent();
  var _default = transitionend;
  _exports.default = _default;
});