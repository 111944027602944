define("ember-simple-auth-auth0/mixins/auth0-data-adapter-mixin", ["exports", "@ember-data/adapter/json-api", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _jsonApi, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var idToken = this.session.data.authenticated.idToken;

      if (Ember.isPresent(idToken)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(idToken));
      }
    }
  });

  _exports.default = _default;
});