define('ember-cli-mentionable/mixins/mentionable-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isPresent = Ember.isPresent,
      computed = Ember.computed;


  var Mentionable = Ember.Object.extend({
    token: '@',
    re: null,
    returnSortedMatches: true,
    returnStartingMatchesFirst: true,
    values: null
  });

  exports.default = Ember.Mixin.create({
    elementClassNames: null,
    actions: {
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },
      didFocusOut: function didFocusOut() {
        this.sendAction('didFocusOut'); // eslint-disable-line ember/closure-actions
      },
      didKeyPress: function didKeyPress(value, event) {
        this.sendAction('didKeyPress', value, event); // eslint-disable-line ember/closure-actions
      },
      didInsertNewline: function didInsertNewline() {
        this.sendAction('didInsertNewline'); // eslint-disable-line ember/closure-actions
      },
      didPressEnter: function didPressEnter() {
        this.sendAction('didPressEnter'); // eslint-disable-line ember/closure-actions
      },
      didPressEscape: function didPressEscape() {
        this.sendAction('didPressEscape'); // eslint-disable-line ember/closure-actions
      },
      didKeyDown: function didKeyDown() {
        this.sendAction('didKeyDown'); // eslint-disable-line ember/closure-actions
      },
      didKeyUp: function didKeyUp(value, event) {
        this.sendAction('didKeyUp', event); // eslint-disable-line ember/closure-actions
        this.focusPicker(event);
        if (event.keyCode !== 38 && event.keyCode !== 40 && event.keyCode !== 13) {
          Ember.run.debounce(this, this.parseMentionables, this.get('debounceTime'));
        }
      },
      didInput: function didInput(event) {
        this.sendAction('didInput', event); // eslint-disable-line ember/closure-actions
      },
      didSelectValue: function didSelectValue(selectedValue) {
        this.updateValue(selectedValue);
        this.sendAction('didSelectValue', selectedValue); // eslint-disable-line ember/closure-actions
      },
      focusInput: function focusInput() {
        this.$(this.get('inputSelector')).focus();
      },
      updateKeypress: function updateKeypress(event) {
        var $input = this.$(this.get('inputSelector'));
        var value = $input.val();
        $input.focus().val('' + value + event.key);
      }
    },

    config: null,
    value: null,
    mentionables: null,
    inputSelector: 'input',
    inputClassNames: 'mentionable-input',
    debounceTime: 100,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var mentionables = Ember.A([]);
      Ember.makeArray(this.get('config')).map(function (configItem) {
        Ember.assert('Values must be an array!', Array.isArray(configItem.values));

        // make a copy of values to keep from mutating on sort
        var values = Ember.A(configItem.values.slice());

        var mentionable = Mentionable.create(configItem);
        mentionable.set('re', new RegExp('(^|\\W+)' + mentionable.get('token') + '\\w*$', 'gi'));

        if (mentionable.get('returnSortedMatches')) {
          var searchProperty = mentionable.get('searchProperty');
          values = isPresent(searchProperty) ? values.sortBy(searchProperty) : values.sort();
        }

        mentionable.set('values', values);
        mentionables.addObject(mentionable);
      });

      this.set('mentionables', mentionables);
    },
    parseMentionables: function parseMentionables() {
      var _this = this;

      this.set('matchingValues', null);
      this.set('matchingValuesPending', true);
      var promises = Ember.A([]);
      this.get('mentionables').map(function (mentionable) {
        promises.addObject(_this.parseMentionable(mentionable));
      });

      Ember.RSVP.all(promises).finally(function () {
        _this.set('matchingValuesPending', false);
      });
    },
    parseMentionable: function parseMentionable(mentionable) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var text = '' + _this2.get('value');
        var match = text.match(mentionable.get('re'));
        if (match !== null) {
          _this2.set('match', match[0].trim());
          _this2.set('token', mentionable.get('token'));
          var matchText = match[0].split(mentionable.get('token'))[1];
          _this2.searchValues(matchText, mentionable).then(function (matchingValues) {
            _this2.set('matchingValues', matchingValues);
            _this2.set('searchProperty', mentionable.get('searchProperty'));
          }).finally(function () {
            resolve();
          });
        } else {
          resolve();
        }
      });
    },
    searchValues: function searchValues(text, mentionable) {
      return new Ember.RSVP.Promise(function (resolve /* , reject */) {
        var values = mentionable.get('values');
        var searchProperty = mentionable.get('searchProperty');
        var returnStartingMatchesFirst = mentionable.get('returnStartingMatchesFirst');
        var matchingValues = Ember.A([]);
        var matchingStartsWith = Ember.A([]);
        var matchingIncludes = Ember.A([]);
        if (text.length === 0) {
          matchingValues = values;
        } else {
          values.map(function (value) {
            var searchValue = value;
            if (isPresent(searchProperty)) {
              searchValue = Ember.Object.create(value).get(searchProperty);
            }
            if (returnStartingMatchesFirst && searchValue.toLowerCase().startsWith(text.toLowerCase())) {
              matchingStartsWith.addObject(value);
            } else if (searchValue.toLowerCase().includes(text.toLowerCase())) {
              matchingIncludes.addObject(value);
            }
          });
          matchingValues.addObjects(matchingStartsWith);
          matchingValues.addObjects(matchingIncludes);
        }

        resolve(matchingValues);
      });
    },
    updateValue: function updateValue(selectedValue) {
      var searchProperty = this.get('searchProperty');
      if (isPresent(searchProperty)) {
        selectedValue = Ember.Object.create(selectedValue).get(searchProperty);
      }
      var value = this.get('value').replace(this.get('match'), '');
      var token = this.get('token');
      this.set('value', '' + value + token + selectedValue + ' ');
      this.set('matchingValues', null);
      this.$(this.get('inputSelector')).focus();
    },


    /*
      properties for matchingValues
    */
    matchingValues: null,
    matchingValuesPending: null,
    showPicker: computed('matchingValues', 'matchingValuesPending', function () {
      return this.get('matchingValues') !== null || this.get('matchingValuesPending') === true;
    }),
    pickerClass: 'mentionable-picker',
    noMatchingValuesMessage: 'no matching items found.',
    matchingValuesPendingMessage: 'loading...',
    pickerItemPartial: null,
    focusPicker: function focusPicker(event) {
      if ((event.keyCode === 38 || event.keyCode === 40) && isPresent(this.get('matchingValues'))) {
        this.$('.' + this.get('pickerClass')).focus();
      }
    }
  });
});