define("ember-paper/templates/components/paper-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DDGgNx6M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"md-text\"],[12],[18,1,null],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-paper/templates/components/paper-option.hbs"
  });

  _exports.default = _default;
});