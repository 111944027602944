define("ember-simple-auth-auth0/mixins/application-route-mixin", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth-auth0/utils/errors", "ember-simple-auth-auth0/utils/get-session-expiration", "ember-simple-auth-auth0/utils/now"], function (_exports, _applicationRouteMixin, _errors, _getSessionExpiration, _now) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    auth0: Ember.inject.service(),
    inTesting: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),
    sessionAuthenticated: function sessionAuthenticated() {
      this._setupFutureEvents();

      this._super.apply(this, arguments);
    },

    /**
     * Hook that gets called after the jwt has expired
     * but before we notify the rest of the system.
     * Great place to add cleanup to expire any third-party
     * tokens or other cleanup.
     *
     * IMPORTANT: You must return a promise, else logout
     * will not continue.
     *
     * @return {Promise}
     */
    beforeSessionExpired: function beforeSessionExpired() {
      return Ember.RSVP.resolve();
    },

    /**
     * This has to be overridden because the default behavior prevents
     * auth0 to logout correctly.
     */
    sessionInvalidated: function sessionInvalidated() {
      this._clearJobs();

      return this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel() {
      this._setupFutureEvents();

      var promise = Ember.RSVP.resolve(this._super.apply(this, arguments));
      promise = promise.then(this._getUrlHashData.bind(this)).then(this._authenticateWithUrlHash.bind(this));
      return promise;
    },
    willDestroy: function willDestroy() {
      this._clearJobs();
    },
    _authenticateWithUrlHash: function _authenticateWithUrlHash(urlHashData) {
      if (Ember.isEmpty(urlHashData) || typeof FastBoot !== 'undefined') {
        return;
      }

      return this.session.authenticate('authenticator:auth0-url-hash', urlHashData).then(this._clearUrlHash.bind(this));
    },
    _getUrlHashData: function _getUrlHashData() {
      if (typeof FastBoot !== 'undefined') {
        return;
      }

      var auth0 = this.auth0._getAuth0Instance();

      var enableImpersonation = this.auth0.enableImpersonation;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        auth0.parseHash({
          __enableImpersonation: enableImpersonation
        }, function (err, parsedPayload) {
          if (err) {
            return reject(new _errors.Auth0Error(err));
          }

          resolve(parsedPayload);
        });
      });
    },
    _clearUrlHash: function _clearUrlHash() {
      if (typeof FastBoot === 'undefined' && !this.inTesting && window.history) {
        window.history.pushState('', document.title, window.location.pathname + window.location.search);
      }

      return Ember.RSVP.resolve();
    },
    _setupFutureEvents: function _setupFutureEvents() {
      // Don't schedule expired events during testing, otherwise acceptance tests will hang.
      if (this.inTesting || typeof FastBoot !== 'undefined') {
        return;
      } // [XA] only actually schedule events if we're authenticated.


      if (this.session.isAuthenticated) {
        this._scheduleRenew();

        this._scheduleExpire();
      }
    },
    _scheduleJob: function _scheduleJob(jobName, jobFn, timeInMilli) {
      Ember.run.cancel(this.jobName);
      var job = Ember.run.later(this, jobFn, timeInMilli);
      Ember.set(this, jobName, job);
    },
    _scheduleRenew: function _scheduleRenew() {
      var renewInMilli = this.auth0.silentAuthRenewSeconds * 1000;

      if (renewInMilli) {
        this._scheduleJob('_renewJob', this._processSessionRenewed, renewInMilli);
      }
    },
    _scheduleExpire: function _scheduleExpire() {
      var expireInMilli = this._jwtRemainingTimeInSeconds * 1000;

      this._scheduleJob('_expireJob', this._processSessionExpired, expireInMilli);
    },

    /**
     * The current JWT's expire time
     * @return {Date of expiration}
     */
    _expiresAt: Ember.computed('session.{data.authenticated,isAuthenticated}', {
      get: function get() {
        if (!this.session.isAuthenticated) {
          return 0;
        }

        var sessionData = this.session.data.authenticated;
        return (0, _getSessionExpiration.default)(sessionData);
      }
    }),

    /**
     * Number of seconds until the JWT expires.
     * @return {Number in seconds}
     */
    _jwtRemainingTimeInSeconds: Ember.computed('_expiresAt', {
      get: function get() {
        var _this$_expiresAt;

        var remaining = ((_this$_expiresAt = this._expiresAt) !== null && _this$_expiresAt !== void 0 ? _this$_expiresAt : 0) - (0, _now.default)();
        return remaining < 0 ? 0 : remaining;
      }
    }),
    _clearJobs: function _clearJobs() {
      Ember.run.cancel(this._renewJob);
      Ember.run.cancel(this._expireJob);
    },
    _processSessionRenewed: function _processSessionRenewed() {
      // [XA] need to refactor this a bit. This is kinda bonkers-spaghetti right now.
      return this._trySilentAuth().then(this._scheduleRenew.bind(this), this._setupFutureEvents.bind(this));
    },
    _processSessionExpired: function _processSessionExpired() {
      return this.beforeSessionExpired().then(this._trySilentAuth.bind(this)).then(this._invalidateIfAuthenticated.bind(this), this._scheduleExpire.bind(this)); // reschedule expiration if we re-authenticate.
    },
    _trySilentAuth: function _trySilentAuth() {
      var _this = this;

      if (this.auth0.silentAuthOnSessionExpire) {
        // Try silent auth, but reverse the promise results.
        // since a rejecting promise during expiration means
        // "don't expire", we want to reject on success and
        // resolve on failure. Strange times.
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.session.authenticate('authenticator:auth0-silent-auth').then(reject, resolve);
        });
      }

      return Ember.RSVP.resolve();
    },
    _invalidateIfAuthenticated: function _invalidateIfAuthenticated() {
      if (this.session.isAuthenticated) {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});