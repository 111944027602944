define("ember-paper/components/paper-autocomplete/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qzf/NFsX",
    "block": "{\"symbols\":[\"token\",\"&attrs\"],\"statements\":[[11,\"span\"],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"tokens\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"isMatch\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"span\"],[14,0,\"highlight\"],[12],[1,[32,1,[\"text\"]]],[13]],\"parameters\":[]},{\"statements\":[[1,[32,1,[\"text\"]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-paper/components/paper-autocomplete/highlight/template.hbs"
  });

  _exports.default = _default;
});