define("ember-paper/components/paper-sidenav-toggle", ["exports", "ember-paper/templates/components/paper-sidenav-toggle"], function (_exports, _paperSidenavToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperSidenavToggle
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperSidenavToggle.default,
    tagName: '',
    name: 'default',
    paperSidenav: Ember.inject.service(),
    toggle: function toggle() {
      this.get('paperSidenav').toggle(this.get('name'));
    }
  });

  _exports.default = _default;
});