define("ember-table/-private/utils/ember", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notifyPropertyChange = void 0;

  /* globals Ember */

  /* eslint-disable ember/new-module-imports */
  var notifyPropertyChange = true ? Ember.notifyPropertyChange : Ember.propertyDidChange;
  _exports.notifyPropertyChange = notifyPropertyChange;
});