define("ember-table/components/ember-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z8f7xsHB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"columnValue\"]],[32,0,[\"columnMeta\"]],[32,0,[\"rowMeta\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"columnValue\",\"name\"]]],[2,\"\\n\\n  \"],[1,[30,[36,0],null,[[\"columnMeta\"],[[32,0,[\"columnMeta\"]]]]]],[2,\"\\n\\n  \"],[1,[30,[36,1],null,[[\"columnMeta\"],[[32,0,[\"columnMeta\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ember-th/sort-indicator\",\"ember-th/resize-handle\",\"if\"]}",
    "moduleName": "ember-table/components/ember-th/template.hbs"
  });

  _exports.default = _default;
});