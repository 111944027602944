define("ember-simple-auth-auth0/utils/create-session-data-object", ["exports", "ember-simple-auth-auth0/utils/now"], function (_exports, _now) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createSessionDataObject;

  function createSessionDataObject(profile, tokenInfo) {
    var sessionData = {
      issuedAt: (0, _now.default)()
    };
    Ember.assign(sessionData, tokenInfo, {
      profile: profile
    });
    return sessionData;
  }
});