define("ember-paper/components/paper-radio-group-label", ["exports", "ember-paper/templates/components/paper-radio-group-label"], function (_exports, _paperRadioGroupLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _paperRadioGroupLabel.default,
    tagName: 'md-label',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('setAriaLabelledby')) {
        this.get('setAriaLabelledby')(this.elementId);
      }
    }
  });

  _exports.default = _default;
});