define("ember-simple-auth-auth0/utils/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Auth0Error = Auth0Error;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function Auth0Error(payload) {
    var message = 'Auth0 operation failed';

    if (_typeof(payload) === 'object' && payload !== null) {
      if (payload.error_description) {
        payload.error_description = decodeURI(payload.error_description);
      }

      var errorCode = payload.error || 'unknown';
      var errorDesc = payload.error_description || message;
      message = "Auth0 returned error `".concat(errorCode, "`: ").concat(errorDesc);
      this.name = errorCode;
    } else if (typeof payload === 'string') {
      message += ": ".concat(payload);
      payload = {};
    }

    Ember.Error.call(this, message);
    this.payload = payload;
  }

  Auth0Error.prototype = Object.create(Ember.Error.prototype);
});