define("ember-table/components/-private/scroll-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fz4rpnem",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showLeft\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__left\"],[15,5,[32,0,[\"leftStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,0,[\"showRight\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__right\"],[15,5,[32,0,[\"rightStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,0,[\"showTop\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__top\"],[15,5,[32,0,[\"topStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,0,[\"showBottom\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"scroll-indicator scroll-indicator__bottom\"],[15,5,[32,0,[\"bottomStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ember-table/components/-private/scroll-indicators/template.hbs"
  });

  _exports.default = _default;
});