define("ember-paper/components/paper-card-icon-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperCardIconActions
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: 'md-card-icon-actions'
  });

  _exports.default = _default;
});