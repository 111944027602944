define("ember-collection/components/ember-collection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lnc3WBjC",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[6,[37,7],null,[[\"content-size\",\"scroll-left\",\"scroll-top\",\"scrollChange\",\"clientSizeChange\"],[[35,6],[35,5],[35,4],[30,[36,3],[[32,0],\"scrollChange\"],null],[30,[36,3],[[32,0],\"clientSizeChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[22,5,[32,1,[\"style\"]]],[12],[18,2,[[32,1,[\"item\"]],[32,1,[\"index\"]]]],[13]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_cells\",\"-track-array\",\"each\",\"action\",\"_scrollTop\",\"_scrollLeft\",\"_contentSize\",\"ember-native-scrollable\"]}",
    "moduleName": "ember-collection/components/ember-collection/template.hbs"
  });

  _exports.default = _default;
});