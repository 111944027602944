define("ember-paper/utils/get-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getParent;
  var ViewUtils = Ember.ViewUtils; // taken from https://github.com/kaliber5/ember-bootstrap/blob/master/addon/utils/get-parent.js

  function getParent(view) {
    if (Ember.get(view, 'tagName') === '') {
      // Beware: use of private API! :(
      if (ViewUtils && ViewUtils.getViewBounds) {
        return ViewUtils.getViewBounds(view).parentElement;
      } else {
        return view._renderNode.contextualElement;
      }
    } else {
      return Ember.get(view, 'element').parentNode;
    }
  }
});