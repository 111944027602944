define("ember-paper/components/paper-dialog-container", ["exports", "ember-invoke-action"], function (_exports, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperDialogContainer
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['md-dialog-container'],
    mouseDown: function mouseDown(ev) {
      this._sourceEl = ev.target;
    },
    mouseUp: function mouseUp(ev) {
      if (this._sourceEl === this.element && ev.target === this.element) {
        ev.stopPropagation();
        ev.preventDefault();
        (0, _emberInvokeAction.invokeAction)(this, 'outsideClicked');
      }
    }
  });

  _exports.default = _default;
});