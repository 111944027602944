define("ember-paper/components/paper-select/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ll5/5Puo",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"md-option\"],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md-text\"],[12],[18,2,null],[13],[2,\"\\n  \"],[8,\"paper-ripple\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-paper/components/paper-select/option/template.hbs"
  });

  _exports.default = _default;
});