define('ember-cli-mentionable/components/mentionable-textarea', ['exports', 'ember-cli-mentionable/mixins/mentionable-component', 'ember-cli-mentionable/templates/components/mentionable-textarea'], function (exports, _mentionableComponent, _mentionableTextarea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mentionableComponent.default, {
    layout: _mentionableTextarea.default,
    classNames: ['mentionable-component', 'mentionable-textarea']
  });
});