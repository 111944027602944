define("ember-paper/templates/components/paper-sidenav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ePHa+k3r",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"opaque\",\"class\",\"onClick\"],[true,\"md-sidenav-backdrop\",[30,[36,0],[[32,0],\"onBackdropTap\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],null,[[\"class\",\"name\",\"position\",\"lockedOpen\",\"closed\",\"closeOnClick\",\"onToggle\"],[[35,8],[35,7],[35,6],[35,5],[35,2],[35,4],[30,[36,0],[[32,0],\"onToggle\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"paper-backdrop\",\"closed\",\"unless\",\"closeOnClick\",\"lockedOpen\",\"position\",\"name\",\"class\",\"paper-sidenav-inner\"]}",
    "moduleName": "ember-paper/templates/components/paper-sidenav.hbs"
  });

  _exports.default = _default;
});