define("ember-paper/mixins/focusable-mixin", ["exports", "ember-paper/mixins/events-mixin", "ember-invoke-action"], function (_exports, _eventsMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FocusableMixin
   * @extends Ember.Mixin
   * @uses EventsMixin
   */
  var _default = Ember.Mixin.create(_eventsMixin.default, {
    disabled: false,
    pressed: false,
    active: false,
    focused: false,
    hover: false,
    classNameBindings: ['focused:md-focused'],
    attributeBindings: ['tabindex', 'disabledAttr:disabled'],
    disabledAttr: Ember.computed('disabled', function () {
      return this.get('disabled') ? 'disabled' : null;
    }),
    // Alow element to be focusable by supplying a tabindex 0
    tabindex: Ember.computed('disabled', function () {
      return this.get('disabled') ? '-1' : '0';
    }),
    toggle: false,
    // Only render the "focused" state if the element gains focus due to
    // keyboard navigation.
    focusOnlyOnKey: false,
    _mouseEnterHandler: undefined,
    _mouseLeaveHandler: undefined,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._mouseEnterHandler = this.handleMouseEnter.bind(this);
      this._mouseLeaveHandler = this.handleMouseLeave.bind(this);
      this.element.addEventListener('mouseenter', this._mouseEnterHandler);
      this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
      this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      this._mouseEnterHandler = undefined;
      this._mouseLeaveHandler = undefined;
    },

    /*
     * Listen to `focusIn` and `focusOut` events instead of `focus` and `blur`.
     * This way we don't need to explicitly bubble the events.
     * They bubble by default.
     */
    focusIn: function focusIn() {
      if (!this.get('disabled') && !this.get('focusOnlyOnKey') || !this.get('pressed')) {
        this.set('focused', true);
      }
    },
    focusOut: function focusOut() {
      this.set('focused', false);
    },
    handleMouseEnter: function handleMouseEnter(e) {
      this.set('hover', true);
      (0, _emberInvokeAction.invokeAction)(this, 'onMouseEnter', e);
    },
    handleMouseLeave: function handleMouseLeave(e) {
      this.set('hover', false);

      this._super(e);

      (0, _emberInvokeAction.invokeAction)(this, 'onMouseLeave', e);
    },
    down: function down() {
      this.set('pressed', true);

      if (this.toggle) {
        this.toggleProperty('active');
      } else {
        this.set('active', true);
      }
    },
    up: function up() {
      this.set('pressed', false);

      if (!this.toggle) {
        this.set('active', false);
      }
    }
  });

  _exports.default = _default;
});