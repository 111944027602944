define("ember-paper/utils/key-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UP_ARROW = _exports.TAB = _exports.SPACE = _exports.RIGHT_ARROW = _exports.LEFT_ARROW = _exports.ESCAPE = _exports.ENTER = _exports.DOWN_ARROW = void 0;
  var ENTER = 13;
  _exports.ENTER = ENTER;
  var ESCAPE = 27;
  _exports.ESCAPE = ESCAPE;
  var SPACE = 32;
  _exports.SPACE = SPACE;
  var LEFT_ARROW = 37;
  _exports.LEFT_ARROW = LEFT_ARROW;
  var UP_ARROW = 38;
  _exports.UP_ARROW = UP_ARROW;
  var RIGHT_ARROW = 39;
  _exports.RIGHT_ARROW = RIGHT_ARROW;
  var DOWN_ARROW = 40;
  _exports.DOWN_ARROW = DOWN_ARROW;
  var TAB = 9;
  _exports.TAB = TAB;
});