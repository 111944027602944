define("@ember-data/debug/setup", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typesMapFor = typesMapFor;
  var StoreTypesMap = new WeakMap();

  function typesMapFor(store) {
    var typesMap = StoreTypesMap.get(store);

    if (typesMap === undefined) {
      typesMap = new Map();
      StoreTypesMap.set(store, typesMap);
    }

    return typesMap;
  } // override _createRecordData to add the known models to the typesMap


  var __createRecordData = _store.default.prototype._createRecordData;

  _store.default.prototype._createRecordData = function (identifier) {
    var typesMap = typesMapFor(this);

    if (!typesMap.has(identifier.type)) {
      typesMap.set(identifier.type, false);
    }

    return __createRecordData.call(this, identifier);
  };

  var _default = {
    name: '@ember-data/data-adapter',
    initialize: function initialize() {}
  };
  _exports.default = _default;
});