define("ember-truth-helpers/helpers/not-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notEqualHelper = notEqualHelper;

  function notEqualHelper(params) {
    return params[0] !== params[1];
  }

  var _default = Ember.Helper.helper(notEqualHelper);

  _exports.default = _default;
});