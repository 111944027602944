define("ember-validators/utils/is-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPromise;
  var canInvoke = Ember.canInvoke;

  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }
});