define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bD8ASV18",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"wrappedRowArray\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\",\"rowsCount\"],[[32,1],[32,0,[\"columns\"]],[32,0,[\"columnMetaCache\"]],[32,0,[\"rowMetaCache\"]],[32,0,[\"canSelect\"]],[32,0,[\"rowSelectionMode\"]],[32,0,[\"checkboxSelectionMode\"]],[32,0,[\"wrappedRowArray\",\"length\"]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,3,[[30,[36,2],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowsCount\",\"row\"],[[32,2,[\"rowValue\"]],[32,2,[\"rowMeta\"]],[32,2,[\"cells\"]],[32,2,[\"rowSelectionMode\"]],[32,2,[\"rowsCount\"]],[30,[36,1],[\"ember-tr\"],[[\"api\"],[[32,2]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"api\"],[[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"ember-tr\",\"component\",\"hash\",\"if\",\"-ember-table-private/row-wrapper\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-table/components/ember-tfoot/template.hbs"
  });

  _exports.default = _default;
});