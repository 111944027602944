define('ember-autosave/autosave-proxy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Store properties off the autosave object to avoid triggering unknownProperty
  // hooks and to avoid conflict with the developer-provided model object.
  var privateStore = new WeakMap();

  var AutosaveProxy = Ember.Object.extend({
    setUnknownProperty: function setUnknownProperty(key, value) {
      var _this = this;

      var privateProps = privateStore.get(this);
      var oldValue = Ember.get(privateProps.target, key);

      if (oldValue !== value) {
        Ember.set(privateProps.target, key, value);
        this.notifyPropertyChange(key);

        var options = privateProps.options;
        if (isConfiguredProperty(options, key)) {
          var saveDelay = options.saveDelay;
          clearTimeout(privateProps.pendingSave);
          privateProps.pendingSave = setTimeout(function () {
            return save(_this);
          }, saveDelay);
        }
      }
    },

    unknownProperty: function unknownProperty(key) {
      return Ember.get(privateStore.get(this).target, key);
    },

    willDestroy: function willDestroy() {
      flushPendingSave(this);
    }
  });

  AutosaveProxy.reopenClass({
    defaultOptions: {
      save: function save(model) {
        model.save();
      },
      saveDelay: 1000,
      context: undefined
    },

    config: function config(options) {
      this.options = options;
    },

    create: function create(target, localOptions) {
      var options = Object.assign({}, this.defaultOptions, // Default library options
      this.options, // Global custom config options
      localOptions // Local custom config options
      );

      var obj = this._super();
      privateStore.set(obj, {
        target: target || {},
        pendingSave: undefined,
        options: options
      });

      return obj;
    }
  });

  function isConfiguredProperty(options, prop) {
    (false && !(!(options.only && options.except)) && Ember.assert("You can configure the `only` option or the `except` option, but not both", !(options.only && options.except)));


    if (options.only) {
      return options.only.indexOf(prop) !== -1;
    } else if (options.except) {
      return options.except.indexOf(prop) === -1;
    } else {
      return true;
    }
  }

  function save(autosaveProxy) {
    var privateProps = privateStore.get(autosaveProxy);
    var _privateProps$options = privateProps.options,
        context = _privateProps$options.context,
        save = _privateProps$options.save;


    var saveFunction = void 0;
    if (typeof save === 'function') {
      saveFunction = save;
    } else {
      saveFunction = context[save];
    }

    privateProps.pendingSave = undefined;

    return saveFunction.call(context, privateProps.target);
  }

  function flushPendingSave(autosaveProxy) {
    if (!autosaveProxy) {
      return;
    }

    var pendingSave = privateStore.get(autosaveProxy).pendingSave;
    if (pendingSave !== undefined) {
      // Cancel the pending debounced function
      clearTimeout(pendingSave);

      // Immediately call save
      return save(autosaveProxy);
    }
  }

  function cancelPendingSave(autosaveProxy) {
    if (!autosaveProxy) {
      return;
    }

    clearTimeout(privateStore.get(autosaveProxy).pendingSave);
  }

  exports.default = AutosaveProxy;
  exports.flushPendingSave = flushPendingSave;
  exports.cancelPendingSave = cancelPendingSave;
});