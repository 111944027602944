define("ember-table/components/ember-tr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYt6p+j9",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"cells\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,0,[\"isHeader\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[30,[36,4],null,[[\"columnValue\",\"columnMeta\",\"sorts\",\"sendUpdateSort\",\"rowMeta\",\"rowsCount\",\"cell\"],[[32,1,[\"columnValue\"]],[32,1,[\"columnMeta\"]],[32,1,[\"sorts\"]],[32,1,[\"sendUpdateSort\"]],[32,1,[\"rowMeta\"]],[32,1,[\"rowsCount\"]],[30,[36,3],[\"ember-th\"],[[\"api\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[18,2,[[30,[36,4],null,[[\"api\",\"cellValue\",\"cellMeta\",\"columnValue\",\"columnMeta\",\"rowValue\",\"rowMeta\",\"rowsCount\",\"cell\"],[[32,1],[32,1,[\"cellValue\"]],[32,1,[\"cellMeta\"]],[32,1,[\"columnValue\"]],[32,1,[\"columnMeta\"]],[32,1,[\"rowValue\"]],[32,1,[\"rowMeta\"]],[32,1,[\"rowsCount\"]],[30,[36,3],[\"ember-td\"],[[\"api\"],[[32,1]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"isHeader\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"api\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"api\"],[[32,1]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"ember-td\",\"ember-th\",\"if\",\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-table/components/ember-tr/template.hbs"
  });

  _exports.default = _default;
});