define('ember-autosave/index', ['exports', 'ember-autosave/autosave-proxy', 'ember-autosave/computed-autosave'], function (exports, _autosaveProxy, _computedAutosave) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'AutosaveProxy', {
    enumerable: true,
    get: function () {
      return _autosaveProxy.default;
    }
  });
  Object.defineProperty(exports, 'flushPendingSave', {
    enumerable: true,
    get: function () {
      return _autosaveProxy.flushPendingSave;
    }
  });
  Object.defineProperty(exports, 'cancelPendingSave', {
    enumerable: true,
    get: function () {
      return _autosaveProxy.cancelPendingSave;
    }
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _computedAutosave.default;
    }
  });
});