define("ember-paper/components/paper-sidenav", ["exports", "ember-paper/templates/components/paper-sidenav", "ember-invoke-action"], function (_exports, _paperSidenav, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperSidenav.default,
    tagName: '',
    name: 'default',
    position: 'left',
    lockedOpen: 'gt-sm',
    open: false,
    closed: Ember.computed.not('open'),
    closeOnClick: true,
    actions: {
      onToggle: function onToggle() {
        _emberInvokeAction.invokeAction.apply(void 0, [this, 'onToggle'].concat(Array.prototype.slice.call(arguments)));
      },
      onBackdropTap: function onBackdropTap() {
        (0, _emberInvokeAction.invokeAction)(this, 'onToggle', false);
      }
    }
  });

  _exports.default = _default;
});