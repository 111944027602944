define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xU8bKhm1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ember-table-overflow\"],[15,1,[31,[[32,0,[\"elementId\"]],\"-overflow\"]]],[12],[2,\"\\n  \"],[10,\"table\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"api\",\"head\",\"body\",\"foot\",\"loadingMore\"],[[32,0,[\"api\"]],[30,[36,0],[\"ember-thead\"],[[\"api\"],[[32,0,[\"api\"]]]]],[30,[36,0],[\"ember-tbody\"],[[\"api\"],[[32,0,[\"api\"]]]]],[30,[36,0],[\"ember-tfoot\"],[[\"api\"],[[32,0,[\"api\"]]]]],[30,[36,0],[\"ember-table-loading-more\"],[[\"api\"],[[32,0,[\"api\"]]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],null,[[\"api\"],[[32,0,[\"api\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-ember-table-private/scroll-indicators\"]}",
    "moduleName": "ember-table/components/ember-table/template.hbs"
  });

  _exports.default = _default;
});