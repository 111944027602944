define("ember-changeset-validations/utils/get-messages", ["exports", "ember-get-config", "ember-changeset-validations/utils/messages", "ember-changeset-validations/utils/with-defaults"], function (_exports, _emberGetConfig, _messages, _withDefaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMessages;
  var keys = Object.keys;
  var moduleName = "".concat(_emberGetConfig.default.modulePrefix, "/validations/messages");
  var cachedRef = null;
  /**
   * Find and load messages module on consuming app. Defaults to addon messages.
   * To define a custom message map, create `my-app/app/validations/messages.js`
   * and export an object.
   *
   * @param  {Object} moduleMap
   * @param  {Boolean} useCache Pass `false` to ignore cached key
   * @return {Object}
   */

  function getMessages() {
    var moduleMap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : requirejs.entries;
    var useCache = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var messagesModule = _messages.default;

    if (useCache && Ember.isPresent(cachedRef)) {
      return cachedRef;
    }

    var moduleKey = Ember.A(keys(moduleMap)).find(function (key) {
      return key === moduleName;
    });

    if (Ember.isPresent(moduleKey)) {
      // Merge the user specified messages with the defaults
      messagesModule = (0, _withDefaults.default)(requireModule(moduleKey).default, messagesModule);
    }

    cachedRef = messagesModule;
    return messagesModule;
  }
});