define("ember-paper/validators/minlength", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.minlength = minlength;

  function minlength(value, minlength) {
    return Ember.isEmpty(minlength) || Ember.isNone(value) || "".concat(value).length >= parseInt(minlength, 10);
  }

  var _default = {
    param: 'minlength',
    message: 'Must have at least %@ characters.',
    validate: minlength
  };
  _exports.default = _default;
});